.Setting {
  .tabList {
    // .link {
    //     height: 50px;
    // }
  }
  .accordion {
    .header {
      button {
        padding: 10px 0;
        font-size: 14px;
        font-weight: 400;
        color: var(--darkLight);
      }
    }
  }
  .formControl,
  input {
    font-size: 14px;
    background-color: transparent !important;
    width: 100% !important;
    height: 50px !important;
    // border-color: #4a4a4a !important;
    // color: #fff;
    &::placeholder {
      color: var(--darkLight);
      opacity: 0.5;
    }
  }
  .personalInfo {
    .formControl {
      background-color: #282839 !important;
    }
  }
}
  