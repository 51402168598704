@import url("./table.scss");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
body {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: var(--darkLight);
  background-color: var(--backgroundColor);
}

.commonTable th,
.commonTable td {
  font-size: 12px;
  line-height: 20px;
  border-color: var(--darkLightGray);
  color: var(--darkLight);
}
.commonTable thead th {
  color: #828282;
  background-color: var(--ThemeDarkGray);
}

.currentColor {
  color: currentColor;
}
.darkLightRed {
  color: var(--darkLightRed);
}
a {
  text-decoration: none;
}
.cursor-pointer {
  cursor: pointer !important;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.navbar-toggler:focus {
  box-shadow: unset !important;
}

input.file {
  top: 0;
  left: 0;
  z-index: 9;
  cursor: pointer;
  opacity: 0;
}
.iconWithText .icn.position-absolute {
  top: 50%;
  transform: translateY(-50%);
}
.gap-10 {
  gap: 10px;
}
.labelTag {
  font-size: 12px;
  min-width: 100px;
}
.labelTag.blueLabel {
  background: #54a3ff6e;
  color: #54a3ff;
}
.labelTag.yellowLabel {
  background: #ffd15b73;
  color: #ffd15b;
}
.labelTag.redLabel {
  background: #ff545466;
  color: #ff5454;
}
.labelTag.greenLabel {
  background: #14c67a6b;
  color: #14c67a;
}
.container {
  max-width: 1226px !important;
}
.tableFilters .btn {
  color: #474747;
  background-color: #2c2c2c;
  border: 1px solid #474747;
  border-radius: 10px;
}
.themeClr {
  color: #fe0000 !important;
}
.themeGreen {
  color: #00b833 !important;
}
.fw-sbold {
  font-weight: 600 !important;
}
.form-control:focus {
  border-color: #fe0000 !important;
  box-shadow: unset !important;
  background-color: none !important;
}
.d-flex .imgWrp {
  flex-shrink: 0;
}
.uploadFile {
  border: 2px dashed #282828;
  border-radius: 20px;
}
.uploadFile > input.file {
  opacity: 0;
  outline: 0 !important;
  box-shadow: unset !important;
}
textarea {
  resize: none;
}
.commonBtn.whiteBordered {
  background: transparent;
  color: var(--darkLightRed);
  border-color: var(--darkLightRed);
}
.commonBtn.grayBtn {
  background: #222222;
  border-color: #222222;
}
.commonBtn {
  background: #cf0a10;
  border-color: #cf0a10;
  height: 40px;
  border-radius: 7px;
  font-size: 14px;
  padding: 0 30px;
  white-space: nowrap;
}
.commonBtn:hover {
  background: #760303 !important;
  border-color: #760303 !important;
  color: #fff !important;
}
input.form-check {
  accent-color: #860000;
  height: 18px;
  width: 18px;
}
.otp > div {
  justify-content: center;
  gap: 10px;
}
.otp input {
  height: 55px;
  width: 55px !important;
  border-radius: 50%;
  border: 1px solid #222222;
  background: #222222;
  color: #747474;
  font-size: 18px;
  outline: 0 !important;
}
.form-control {
  background-color: #222222;
  border-color: #222222;
  height: 50px;
  color: var(--darkLight) !important;
}
section.authLayout .deviden__control {
  height: 55px;
  border-radius: 30px !important;
  background: #222222;
  border-color: #222222;
}
section.authLayout .deviden__control span.deviden__indicator-separator {
  display: none;
}
section.authLayout .deviden__control .deviden__single-value {
  color: #fff;
}
.box {
  /* background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.0581) 0%,
    rgba(255, 255, 255, 0.0308) 99.66%
  ); */
  -webkit-backdrop-filter: blur(32px);
  backdrop-filter: blur(32px);
  border-radius: 10px;
  background: var(--ThemeBg);
  border: 0.920722px solid var(--darkLightGray);
  border-radius: 12px;
}
/* underline */
.underlineTab .nav-link:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -2px;
  height: 3px;
  width: 100%;
  background: #cc0021;
  border-radius: 5px;
  opacity: 0;
}
.underlineTab .nav-link.active:after {
  opacity: 1;
}
.underlineTab .nav-link {
  color: var(--darkLight);
}
.underlineTab .nav-link.active {
  color: #cc0021 !important;
}
/* nav-pills tab */
.navPillsTab .nav-link.active {
  background: #cc0021 !important;
  border-color: #cc0021;
}
.navPillsTab.navPillsTab2 .nav-link.active {
  color: #cc0021;
}
.navPillsTab.navPillsTab2 .nav-link {
  background: var(--ThemeBg) !important;
}
.navPillsTab .nav-link {
  color: #808080;
  padding: 0 25px;
  background: var(--ThemeBg);
  height: 44px;
  border: 1px solid #808080;
}
/* underline */
.tabPillsBar.nav a {
  border-radius: 0;
  height: 55px;
}
.tabPillsBar.nav a.active {
  background: #860000;
}
.tabPillsBar.nav > div.nav-item:first-child a {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.tabPillsBar.nav > div.nav-item:last-child a {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.notLastBorder > *:not(:last-child) {
  border-bottom: 1px solid #585858;
}
.tabPillsBar.nav > div.nav-item:not(:last-child) {
  border-right: 1px solid #2e2e2e;
}
section.Setting button.accordion-button:after {
  display: none;
}
section.Setting button.accordion-button {
  background: transparent;
  box-shadow: unset;
  color: #fff;
  font-weight: 500;
  padding-right: 50px;
}
section.Setting button.accordion-button span.icn {
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #f7f7ff;
  height: 25px;
  width: 25px;
  border-radius: 5px;
}
section.Setting button.accordion-button:not(.collapsed) span.icn {
  background-color: #860000;
}
section.Setting button.accordion-button:not(.collapsed) span.icn:before {
  background: #fff;
}
section.Setting button.accordion-button.collapsed span.icn:after,
section.Setting button.accordion-button span.icn:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  height: 50%;
  width: 2px;
  background: #6f6c90;
}
section.Setting button.accordion-button span.icn:before {
  height: 2px;
  width: 50%;
}

/* table */
td,
th {
  vertical-align: middle;
  white-space: nowrap;
}
/* .commonTable table {
    border-collapse: separate;
    border-spacing: 0 1em;
} */
.commonTable thead tr > th:first-child {
  border-top-left-radius: 10px;
}
.commonTable thead tr > th:last-child {
  border-top-right-radius: 10px;
}
.commonTable tbody tr {
  /* background: #222222; */
  border-right: 10px;
}
.commonTable tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.commonTable tr td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* modal */
.modal .modal-content {
  background: #010101;
  border-radius: 20px;
}

button.navbar-toggler {
  background: #860000 !important;
}
.img-profile {
  width: 40px;
  height: 40px;
}
.img-stock {
  height: 40px;
  width: 40px;
}
img.rounded.avatar-xl.image-path {
  width: 100px;
  height: 100px;
}
span.class-img-icon img {
  width: 50px;
  height: 50px;
}
img.class-img-icon-popular {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
img.stock-list-size {
  width: 40px;
  height: 40px;
}
.cms-content {
  background-color: #010101;
  color: #fff;
}
.textarea-field {
  height: 150px; /* Set the initial height */
  min-height: 150px; /* Ensure the height doesn't go below 200px */
  height: auto; /* Enable auto height adjustment */
  overflow-y: auto; /* Allow vertical scrolling if needed */
}

/* pagination */
.cstmPagination ul.pagination {
  margin: 0;
  gap: 5px;
}

.cstmPagination ul.pagination .page-link {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 50%;
  background: var(--darkLightGray);
  border-color: var(--darkLightGray);
  color: var(--darkLight);
}

.cstmPagination ul.pagination .active .page-link {
  background-color: #fe0000;
  color: #fff;
}

@media (max-width: 575px) {
  .tabPillsBar.nav .nav-item {
    width: 100% !important;
    min-width: 150px;
  }
  .tabPillsBar.nav {
    flex-wrap: unset;
    overflow-x: scroll;
  }
}

/* css */

.scheduleSelect .css-13cymwt-control,
.scheduleSelect .css-t3ipsp-control,
.scheduleSelect .css-t3ipsp-control,
.scheduleSelect .css-1n6sfyn-MenuList,
.scheduleSelect .css-tr4s17-option {
  background-color: black !important;
}
.scheduleSelect .css-1dimb5e-singleValue {
  color: white !important;
}

/* .scheduleSelect .css-tr4s17-option:hover,
.scheduleSelect .css-d7l1ni-option {
} */

.chartIFrame body {
  margin: 0 !important;
}
.cursorPointer {
  cursor: pointer !important;
}

td {
  cursor: pointer !important;
}
.list-group .list-group-item {
  background: #08060600;
  border-color: white;
  color: white;
}

/* .scheduleSelect .css-10wo9uf-option {
} */
 .diego__option {
  background: #08060600 !important;
  border-color: white !important;
  color: white !important;
}

 .diego__option:hover,  .diego__option.active {
  background-color: blue !important;
}
.diego__menu-list {
  background: #000 !important;
}


.valueContent h6{
   margin: 0;
}

.valueContent p{
  margin: 0;
}

.col_red{
  color: #f00;
}

.col_green{
  color: #008000;
}
span.red svg {
  transform: rotate(180deg);
}

