.sidebar {
  width: 256px;
  background: var(--ThemeBg);
  height: 100vh;
  z-index: 999;
  left: 0;
  top: 0;
  transition: 0.4s;
  .top {
    border-bottom: 1px solid var(--darkLightGray);
    // height: 67px;

    .logo {
      img {
        height: 56px;
        object-fit: contain;
      }
    }
  }

  .linkWrpper {
    max-height: calc(100vh - 80px);
    overflow: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .accordionWrp {
      .accordionBtn button {
        &:after {
          position: absolute;
          content: "";
          right: 10px;
          top: 50%;
          transform: translateY(-50%) rotate(135deg);
          height: 7px;
          width: 7px;
          border-bottom: 1px solid #fff;
          border-left: 1px solid #fff;
        }
      }
      &:has(a.active) .accordionBtn button {
        background-color: #cf0a10;
        color: #fff;
        .icn {
          &.stroke {
            path {
              stroke: #fff;
            }
          }
          &.fill {
            path {
              fill: #fff;
            }
          }
        }
      }
      .subLinks {
        font-size: 14px;
        color: #7a7a7a;
        &.active {
          color: #fff;
        }
      }
    }
    .link,
    .accordionBtn button {
      color: #7a7a7a;
      border-radius: 7px;
      background-color: transparent;
      box-shadow: unset;
      font-size: 14px;
      font-weight: 700;
      padding: 15px;
      display: flex;
      align-items: center;
      gap: 10px;

      &.active {
        background-color: #cf0a10;
        color: #fff;

        .icn {
          &.stroke {
            path {
              stroke: #fff;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .sidebar {
    &:not(.active) {
      transform: translateX(-110%);
    }
  }
}
