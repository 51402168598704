.StockCompare {
  form {
    .btnWrpper {
      .btn {
        height: 50px;
        min-width: 200px;
      }
    }
  }
  .strippedTable {
    tbody {
      tr:nth-child(even) {
        background-color: #050505;
      }
    }
  }
}
