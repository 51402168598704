.WalletSidebar {
  height: 100vh;
  background-color: #010101; 
  z-index: 999;   
  right: 0;
  top: 0;
  width: 100%;
  max-width: 350px;
  transition: 0.4s;
  &:not(.active) {
    transform: translateX(120%);
  }
  .BankDropdown {
    &:before{
        position: absolute;
        right: 0;
    }
  }
}