.commonTable {
  th,
  td {
    font-size: 12px;
    line-height: 20px;
    border-color: var(--darkLightGray);
    color: var(--darkLight);
  }
  thead {
    th {
      color: #828282;
      background-color: var(--ThemeDarkGray);
    }
  }
}
