body.darkTheme input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Invert colors to make the icon white */
}

/* Style for Firefox */
body.darkTheme input[type="date"]::-moz-calendar-picker-indicator {
  filter: invert(1); /* Invert colors to make the icon white */
}


body.darkTheme .rdtPicker * {
  border-color: #000 !important;
  color: #000;
}