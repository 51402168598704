header.siteHeader {
  border-bottom: 1px solid var(--darkLightGray);
  .logo {
    img {
      height: 50px;
      object-fit: contain;
    }
  }
  .searchForm {
    input {
      height: 40px;
      background-color: transparent !important;
      min-width: 300px;
      padding-left: 35px;
      font-size: 12px;
      border-color: var(--darkLightGray);
      &::placeholder {
        color: #808080;
        font-weight: 500;
      }
    }
  }
  .ProfileDropdown {
    .Menu {
      right: 0;
      left: unset !important;
      background-color: var(--ThemeBg);
    }
  }
}

.notificationDropdown {
  
  .menu {
    left: unset !important;
    right: 0;
    min-width: 500px;
    .top {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      h4 {
        font-size: 18px;
        .count {
          background-color: #0085ff;
          height: 25px;
          width: 25px;
          font-size: 14px;
        }
      }
    }
    .notificationList {
      > li:not(:last-child) {
        border-bottom: 1px solid #dcdee4;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .menu.darkMode {
    background-color: var(--ThemeBg);
    color: #dcdee4;
    p , h6 {
      color: #dcdee4 !important;
    }
  }
}

@media (max-width: 992px) {
}
