.authLayout {
  .link {
    transition: 0.4s;
    &:hover {
      color: #860000 !important;
    }
  }
  .formWrpper {
    background: #010101;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    z-index: 9;

    .cstmSelect {
    }
    .formControl {
      background-color: #222222 !important;
      height: 50px;
      font-size: 14px;
      &::placeholder {
        color: #fff;
      }
      color: #fff !important;

    }
    .btnWrpper {
      button {
        height: 50px;
      }
    }
  }
}
@media (max-width: 575px) {
  .authLayout {
    .formWrpper {
      .top {
        .Btn {
          position: unset !important;
          margin-bottom: 20px;
        }
      }
    }
  }
}
