.support {
  .head {
    h6 {
      font-size: 22px;
      line-height: 26px;
    }
    p {
      font-size: 16px;
      line-height: 22px;
    }
  }
  form {
    .formControl {
      font-size: 12px;
      background-color: #fff !important;
      color: #000 !important;
    }
  }
}
